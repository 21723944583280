<mat-toolbar>
    <button 
        mat-icon-button class="example-icon favorite-icon" 
        aria-label="Example icon-button with heart icon"
        routerLink="" 
        routerLinkActive="active">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon>
    </button>
        <button mat-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
        
    <mat-menu #menu="matMenu">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
    </mat-menu>
    <span>Nettingale</span>
    <span class="example-spacer"></span>
</mat-toolbar>
  
  
