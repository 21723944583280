import { Component } from '@angular/core';

@Component({
  selector: 'app-toolbar-component',
  templateUrl: './toolbar-component.component.html',
  styleUrl: './toolbar-component.component.scss'
})
export class ToolbarComponent {

}
